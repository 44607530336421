// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBdQMt3n2ZtAkiHdOhelw8b5s0nYeu1rbk",
  authDomain: "jmupark-d283e.firebaseapp.com",
  projectId: "jmupark-d283e",
  storageBucket: "jmupark-d283e.appspot.com",
  messagingSenderId: "138139695150",
  appId: "1:138139695150:web:2ee596bcff8c17ecca07bf",
  measurementId: "G-6322TRM6MH"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);

