import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { JMU_DARK_PURPLE } from '../common/constants';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { toast, ToastContainer } from 'react-toastify';
import { getActualAndPrediction } from '../common/graphingUtils';
import { Line } from 'react-chartjs-2';
import { isMobile } from 'react-device-detect';
import { useGetPredictionDataMutation } from '../api/api';
import { getErrorMessage } from '../common/getErrorMessage';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../config/firebaseConfig'; // Import RTK Query hooks

export default function PredictionGraph() {
  const [inputDate, setInputDate] = useState<Date>(new Date());
  const [inputDeckId, setInputDeckId] = useState<string>('0');

  const [getPrediction, { isLoading: loading, error }] =
    useGetPredictionDataMutation();

  const [options, setOptions] = useState<any>(null);
  const [dataPoints, setDataPoints] = useState<any>(null);
  const [modelError, setModelError] = useState<number | null>(null);

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    try {
      logEvent(analytics, 'prediction_graph', {
        page_title: 'PredictionGraph.tsx',
        date: inputDate.toDateString(),
        deckId: Number(inputDeckId),
      });

      const response = await getPrediction({
        deckId: Number(inputDeckId),
        day: inputDate.getDate(),
        month: inputDate.getMonth() + 1,
        year: inputDate.getFullYear(),
      }).unwrap();
      const { PredictedData, Data } = response;
      const { ops, data } = getActualAndPrediction(
        inputDate,
        Data,
        PredictedData
      );

      let meanError = 0;
      for (let i = 0; i < PredictedData.length; i++) {
        const actual = Data[i]?.capacity ?? 0;
        const predicted = PredictedData[i];
        const difference = actual - predicted;
        if (actual === 0) {
          meanError += 1;
        } else {
          meanError += (difference / actual);
        }
      }

      let calculatedModelError = 0;
      if (PredictedData.length === 0) {
        calculatedModelError = 0;
      } else {
        calculatedModelError = (meanError / PredictedData.length) * 100;
      }

      setModelError(calculatedModelError);
      setOptions(ops);
      setDataPoints(data);
    } catch (e) {
      toast.error(`Failed to get prediction ${getErrorMessage(e)}`);
    }
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      rowGap={'25px'}
    >
      <Typography variant={'h4'}>Past and Prediction Graphs</Typography>
      <ToastContainer />

      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        rowGap={'25px'}
      />

      <Box
        display={'flex'}
        justifyContent={'center'}
        width={window.innerWidth}
        maxWidth={1200}
        height={isMobile ? '500px' : 'auto'}
      >
        {loading ? (
          <CircularProgress style={{ color: JMU_DARK_PURPLE }} />
        ) : (
          dataPoints !== null &&
          options !== null &&
          !error && (
            <Line
              height={isMobile ? '500px' : '35px'}
              width={'100%'}
              options={options}
              data={dataPoints}
            />
          )
        )}
      </Box>

      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        rowGap={'25px'}
      >
        {modelError !== null && (
          <Typography>
            This model has a {modelError.toFixed(2)}% error rate for {inputDeckId} {inputDate.toDateString()}.
          </Typography>
        )}

        {/* DatePicker and Select elements should show while loading */}
        <DatePicker
          value={dayjs(inputDate)}
          onChange={(e: Dayjs | null) => {
            if (e) setInputDate(e.toDate());
          }}
        />

        <FormControl variant={'outlined'}>
          <InputLabel id="select-form">Parking Decks</InputLabel>
          <Select
            onChange={(e) => setInputDeckId(e.target.value)}
            label="Parking Decks"
            value={inputDeckId}
          >
            <MenuItem value={'0'}>Champions Deck</MenuItem>
            <MenuItem value={'1'}>Chesapeake Deck</MenuItem>
            <MenuItem value={'2'}>East Campus Deck/Ballard Deck</MenuItem>
            <MenuItem value={'3'}>Grace Deck</MenuItem>
            <MenuItem value={'4'}>Warsaw Deck</MenuItem>
          </Select>
        </FormControl>

        <Button
          fullWidth={isMobile}
          sx={{ mb: 8 }}
          onClick={handleSubmit}
          variant={'contained'}
          style={{ backgroundColor: JMU_DARK_PURPLE }}
          disabled={loading} // Disable the button while loading
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
