export function getErrorMessage(error: any): string {
  // Default error message
  let errorMessage = 'Server is down.';

  // Handle if it's a network or fetch-related error (e.g., API down)
  if (error && error.name === 'FetchBaseQueryError') {
    if (error.status === 'FETCH_ERROR') {
      errorMessage = 'Network error. Unable to reach the API.';
    } else if (error.status === 'PARSING_ERROR') {
      errorMessage = 'API returned an invalid response.';
    } else if (error.status === 500) {
      errorMessage = 'Internal server error (500). Please try again later.';
    } else if (error.status >= 400 && error.status < 500) {
      errorMessage = `Client error (${error.status}): ${error.data?.Error || 'An error occurred'}`;
    } else if (error.status >= 500) {
      errorMessage = `Server error (${error.status}): ${error.data?.Error || 'An error occurred'}`;
    }
  }
  // Handle RTK Query "reject" cases (e.g., API returned an error response)
  else if (error && error.status === 'CUSTOM_ERROR') {
    errorMessage = error.data?.Error || 'An unknown custom error occurred';
  }
  // Handle if it's an instance of an error (from try-catch)
  else if (error instanceof Error) {
    errorMessage = error.message;
  }

  return errorMessage;
}