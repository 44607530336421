import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import store from './store';
import { Provider } from 'react-redux';

// Ensure that TypeScript knows that the element exists by using `HTMLElement | null`
const rootElement = document.getElementById('root');

// Add a check to ensure that `rootElement` is not null before proceeding
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement as HTMLElement);

  root.render(
    <Router>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
          {' '}
          {/* Wrap your app with the Provider */}
          <App />
        </Provider>
      </LocalizationProvider>
    </Router>
  );
}
