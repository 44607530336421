import React, { useEffect } from 'react';
import ParkingLocation from './ParkingLocation';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { JMU_DARK_PURPLE } from '../common/constants';
import { useNavigate } from 'react-router-dom';
import { useGetCapacityQuery, useGetDocumentCountQuery } from '../api/api';
import { getErrorMessage } from '../common/getErrorMessage'; // Import the hooks generated by RTK Query

export default function MainPage() {
  const navigate = useNavigate();
  const targetDate = new Date();

  const {
    data: capacitiesData,
    error: capacitiesError,
    isLoading: gettingCapacities,
  } = useGetCapacityQuery({});
  const {
    data: documentCountData,
    error: documentCountError,
    isLoading: gettingDataPointCount,
  } = useGetDocumentCountQuery();

  useEffect(() => {
    if (capacitiesError) {
      toast.error(
        `Error in getting capacities: ${getErrorMessage(capacitiesError)}`
      );
    }

    if (documentCountError) {
      toast.error(
        `Error in getting document count: ${getErrorMessage(documentCountError)}`
      );
    }
  }, [capacitiesError, documentCountError]);

  return (
    <Box>
      <ToastContainer />
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography variant={'h4'} textAlign="center">
          Welcome to JMU Park
        </Typography>
        <Box
          display="flex"
          justifyContent="start"
          flexDirection="column"
          rowGap="25px"
        >
          <Typography variant={'h5'}>
            This website uses public data gathered from JMU's parking decks. It
            attempts to predict future parking spaces based on previously
            gathered data.
          </Typography>
          {/* Other text elements */}
          {gettingDataPointCount ? (
            <CircularProgress style={{ color: JMU_DARK_PURPLE }} />
          ) : (
            <Typography variant={'h5'}>
              There are currently {documentCountData?.Count} data points that
              are used to train this model.
            </Typography>
          )}
        </Box>
        <Box display={'flex'} gap={'15px'} sx={{ mt: 3, mb: 3 }}>
          <Button
            style={{ backgroundColor: JMU_DARK_PURPLE }}
            variant={'contained'}
            onClick={() => {
              navigate('/prediction');
            }}
          >
            Past Graphs
          </Button>

          <Button
            style={{ backgroundColor: JMU_DARK_PURPLE }}
            variant={'contained'}
            onClick={() => {
              navigate('/prediction/only');
            }}
          >
            Prediction Graphs
          </Button>
        </Box>
      </Box>

      {!gettingCapacities ? (
        <Box
          display="flex"
          flexDirection={'column'}
          justifyContent="center"
          rowGap="25px"
        >
          {capacitiesData && (
            <>
              <ParkingLocation
                name="Champions Deck"
                id="0"
                capacity={capacitiesData['Champions Deck']}
                targetDate={targetDate}
              />
              <ParkingLocation
                name="Chesapeake Deck"
                id="1"
                capacity={capacitiesData['Chesapeake Deck']}
                targetDate={targetDate}
              />
              <ParkingLocation
                name="Ballard Deck (East Deck)"
                id="2"
                capacity={capacitiesData['Ballard Deck']}
                targetDate={targetDate}
              />
              <ParkingLocation
                name="Grace Deck"
                id="3"
                capacity={capacitiesData['Grace Deck']}
                targetDate={targetDate}
              />
              <ParkingLocation
                name="Warsaw Deck"
                id="4"
                capacity={capacitiesData['Warsaw Deck']}
                targetDate={targetDate}
              />
            </>
          )}
        </Box>
      ) : (
        <CircularProgress style={{ color: JMU_DARK_PURPLE }} />
      )}
    </Box>
  );
}
