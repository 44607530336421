import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { JMU_DARK_PURPLE, mod } from '../common/constants';
import { isMobile } from 'react-device-detect';
import { getDataAndOptions, getTimeOptions } from '../common/graphingUtils';
import { Line } from 'react-chartjs-2';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import {
  MoreParkingPredictionResponse,
  useGetCapacityQuery,
  useGetDataPointsQuery,
  useGetPredictionMutation,
} from '../api/api';
import { getErrorMessage } from '../common/getErrorMessage';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../config/firebaseConfig';

export default function MoreParkingInfo() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const name = searchParams.get('name') || 'Parking Deck'; // Fallback if `name` is not provided
  const initialDate = new Date();

  const [selectedDate, setSelectedDate] = useState<Date>(initialDate);
  const [selectedTime, setSelectedTime] = useState<string>(
    extractTimeString(initialDate)
  );
  const [predictionData, setPredictionData] =
    useState<null | MoreParkingPredictionResponse>(null);

  // Use RTK Query hooks
  const { data: dataPointsData, isLoading: gettingDataPoints } =
    useGetDataPointsQuery({
      deckId: Number(id),
      day: initialDate.getDate(),
      month: initialDate.getMonth() + 1,
      year: initialDate.getFullYear(),
    });
  const { data: capacityData, isLoading: capacityLoading } =
    useGetCapacityQuery({ id: Number(id) });
  const [getPrediction, { isLoading: predictionLoading }] =
    useGetPredictionMutation();

  useEffect(() => {
    handleGetPrediction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function extractTimeString(d: Date) {
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  function timeStringToSeconds(timeString: string) {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60;
  }

  const handleGetPrediction = async () => {
    try {
      const seconds = timeStringToSeconds(selectedTime);
      logEvent(analytics, 'more_parking_info_prediction', {
        page_title: 'MoreParkingInfo.tsx',
        seconds: seconds,
        date: selectedDate.toDateString(),
        time: selectedTime,
        deckId: Number(id),
      });
      const response = await getPrediction({
        weekday: mod(selectedDate.getDay() - 1, 7),
        seconds: seconds,
        month: selectedDate.getMonth() + 1,
        day: selectedDate.getDate(),
        year: selectedDate.getFullYear(),
        location: Number(id),
      }).unwrap();
      setPredictionData(response);
    } catch (e) {
      toast.error(`Failed to get prediction ${getErrorMessage(e)}`);
    }
  };

  // Render the content
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        rowGap="25px"
      >
        <Typography variant="h3" textAlign="center">
          {name}
        </Typography>
        <Box maxWidth={1200} width={window.innerWidth}>
          {gettingDataPoints || !dataPointsData ? (
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <CircularProgress style={{ color: JMU_DARK_PURPLE }} />
            </Box>
          ) : (
            <Card>
              <CardContent>
                <Line
                  height={isMobile ? '500px' : '35px'}
                  width={'100%'}
                  data={
                    getDataAndOptions(
                      selectedDate,
                      dataPointsData.Data,
                      getTimeOptions(),
                      name
                    ).data
                  }
                  options={
                    getDataAndOptions(
                      selectedDate,
                      dataPointsData.Data,
                      getTimeOptions(),
                      name
                    ).ops
                  }
                />
              </CardContent>
            </Card>
          )}
        </Box>

        {capacityLoading ? (
          <CircularProgress style={{ color: JMU_DARK_PURPLE }} />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            rowGap="25px"
            mt={isMobile ? 5 : 0}
          >
            <Typography variant="h3">{capacityData?.Capacity}</Typography>
          </Box>
        )}

        <Typography variant="h6">Spots open at {name}</Typography>
        <Typography variant="h6">
          The model predicts that there will be approximately
        </Typography>
        {predictionLoading ? (
          <CircularProgress style={{ color: JMU_DARK_PURPLE }} />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            rowGap="25px"
          >
            <Typography variant="h3">
              {predictionData?.Prediction}{' '}
              {predictionData &&
              predictionData.Prediction > capacityData?.Capacity ? (
                <TrendingUpIcon color="success" />
              ) : (
                <TrendingDownIcon color="error" />
              )}
            </Typography>
            <Typography variant="h6">
              spots open on {selectedDate.toDateString()} at {selectedTime}
            </Typography>
          </Box>
        )}

        <Box display={'flex'} gap={2}>
          <TextField
            label="Date"
            fullWidth={isMobile}
            type="date"
            size="medium"
            onChange={(e) => {
              const d = new Date(e.target.value);
              setSelectedDate(d);
            }}
          />
          <TextField
            type="time"
            value={selectedTime}
            fullWidth={isMobile}
            onChange={(e) => {
              const t = e.target.value;
              setSelectedTime(t);
            }}
          />
        </Box>

        <Button
          sx={{
            mb: 8,
          }}
          fullWidth={isMobile}
          variant="contained"
          style={{ backgroundColor: JMU_DARK_PURPLE }}
          onClick={() => {
            handleGetPrediction();
          }}
          disabled={predictionLoading}
        >
          Predict
        </Button>
      </Box>
    </Box>
  );
}
