import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

interface DataPoint {
  deckId: number;
  timeOfDay: number;
  weekday: number;
  day: number;
  month: number;
  year: number;
  capacity: number;
}

interface PredictionDataResponse {
  Data: DataPoint[];
  PredictedData: number[];
  Error?: string | null;
}

interface PredictionOnlyDataResponse {
  PredictedData: number[];
  Error?: string | null;
}

export interface MoreParkingPredictionResponse {
  Prediction: number;
  Error?: string | null;
}

export const api = createApi({
  reducerPath: 'jmuparkApi', // Unique key to store in the Redux state
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    // Get past graphs
    getPastGraphs: builder.query<
      any,
      {
        lyear: number;
        lmonth: number;
        lday: number;
        uyear: number;
        umonth: number;
        uday: number;
        deckName: string;
      }
    >({
      query: ({ lyear, lmonth, lday, uyear, umonth, uday, deckName }) => ({
        url: `/graphs/past`,
        params: { lyear, lmonth, lday, uyear, umonth, uday, deckName },
      }),
    }),

    // Get current deck graphs
    getDeckGraphs: builder.query<any, number>({
      query: (deckId) => `/graphs/${deckId}`,
    }),

    // Get data points for a specific day
    getDataPoints: builder.query<
      any,
      { deckId: number; day: number; month: number; year: number }
    >({
      query: ({ deckId, day, month, year }) => ({
        url: `/data/${deckId}`,
        params: { day, month, year },
      }),
    }),

    // Get prediction data points
    getPredictionData: builder.mutation<
      PredictionDataResponse,
      { deckId: number; day: number; month: number; year: number }
    >({
      query: ({ deckId, day, month, year }) => ({
        url: `/data/prediction/${deckId}`,
        method: 'GET',
        params: { day, month, year },
      }),
    }),

    // Get prediction data points
    getPredictionOnlyData: builder.mutation<
      PredictionOnlyDataResponse,
      { deckId: number; day: number; month: number; year: number }
    >({
      query: ({ deckId, day, month, year }) => ({
        url: `/data/prediction/only/${deckId}`,
        method: 'GET',
        params: { day, month, year },
      }),
    }),

    // Get past range graph data
    getRangeGraphData: builder.query<
      any,
      {
        deckId: number;
        lyear: number;
        lmonth: number;
        lday: number;
        uyear: number;
        umonth: number;
        uday: number;
      }
    >({
      query: ({ deckId, lyear, lmonth, lday, uyear, umonth, uday }) => ({
        url: `/data/past/${deckId}`,
        params: { lyear, lmonth, lday, uyear, umonth, uday },
      }),
    }),

    // Get prediction graphs
    getPredictionGraphs: builder.query<
      any,
      { deckId: number; day: number; month: number; year: number }
    >({
      query: ({ deckId, day, month, year }) => ({
        url: `/graphs/prediction`,
        params: { day, month, year, deckId },
      }),
    }),

    // Get capacity of parking decks
    getCapacity: builder.query<any, { id?: number }>({
      query: (args) => ({
        url: `/capacity`,
        params: args ? { id: args.id } : {},
      }),
    }),

    // Get prediction for a specific time
    getPrediction: builder.mutation<
      MoreParkingPredictionResponse,
      {
        weekday: number;
        seconds: number;
        month: number;
        day: number;
        year: number;
        location: number;
      }
    >({
      query: ({ weekday, seconds, month, day, year, location }) => ({
        url: `/prediction`,
        params: { weekday, seconds, month, day, year, location },
      }),
    }),

    getPredictionQ: builder.query<
      MoreParkingPredictionResponse,
      {
        weekday: number;
        seconds: number;
        month: number;
        day: number;
        year: number;
        location: number;
      }
    >({
      query: ({ weekday, seconds, month, day, year, location }) => ({
        url: `/prediction`,
        params: { weekday, seconds, month, day, year, location },
      }),
    }),

    // Submit feedback
    submitFeedback: builder.mutation<any, { feedback: string }>({
      query: (feedback) => ({
        url: `/feedback`,
        method: 'POST',
        body: feedback,
      }),
    }),

    // Get document count
    getDocumentCount: builder.query<any, void>({
      query: () => `/count`,
    }),
  }),
});

export const {
  useGetPastGraphsQuery,
  useGetDeckGraphsQuery,
  useGetDataPointsQuery,
  useGetPredictionDataMutation,
  useGetRangeGraphDataQuery,
  useGetPredictionGraphsQuery,
  useGetCapacityQuery,
  useGetPredictionMutation,
  useGetPredictionQQuery,
  useSubmitFeedbackMutation,
  useGetDocumentCountQuery,
  useGetPredictionOnlyDataMutation,
} = api;
