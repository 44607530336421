import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { JMU_DARK_PURPLE, mod } from '../common/constants';
import { isMobile } from 'react-device-detect';
import { getDataAndOptions, getTimeOptions } from '../common/graphingUtils';
import { Line } from 'react-chartjs-2';
import { useGetDataPointsQuery, useGetPredictionQQuery } from '../api/api';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { getErrorMessage } from '../common/getErrorMessage';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../config/firebaseConfig';

// Register the necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale, // This registers the 'linear' scale
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

interface ParkingLocationProps {
  name: string;
  id: string;
  capacity: number;
  targetDate: Date;
}

export default function ParkingLocation({
  name,
  id,
  capacity,
  targetDate,
}: ParkingLocationProps) {
  const navigate = useNavigate();

  const d = new Date();
  const secondsFromMidnight =
    d.getHours() * 3600 + d.getMinutes() * 60 + d.getSeconds();

  const {
    data: predictionData,
    isLoading: predictionLoading,
    error: predictionError,
  } = useGetPredictionQQuery({
    weekday: mod(d.getDay() - 1, 7),
    seconds: secondsFromMidnight,
    month: d.getMonth() + 1,
    day: d.getDate(),
    year: d.getFullYear(),
    location: Number(id),
  });

  const {
    data: dataPointsData,
    isLoading: gettingDataPoints,
    error: dataPointsError,
  } = useGetDataPointsQuery({
    deckId: Number(id),
    day: targetDate.getDate(),
    month: targetDate.getMonth() + 1,
    year: targetDate.getFullYear(),
  });

  const [dataPoints, setDataPoints] = useState<any>(null);
  const [options, setOptions] = useState<any>(null);

  useEffect(() => {
    if (dataPointsData && !dataPointsError) {
      const { ops, data } = getDataAndOptions(
        targetDate,
        dataPointsData.Data,
        getTimeOptions(),
        name
      );
      setDataPoints(data);
      setOptions(ops);
    } else if (dataPointsError) {
      toast.error(
        `Error fetching data points: ${getErrorMessage(dataPointsError)}`
      );
    }
  }, [dataPointsData, dataPointsError, name, targetDate]);

  useEffect(() => {
    if (predictionError) {
      toast.error(
        `Error fetching prediction: ${getErrorMessage(predictionError)}`
      );
    }
  }, [predictionError]);

  const onGraphClick = () => {
    logEvent(analytics, 'parking_location', {
      page_title: 'ParkingLocation.tsx',
      name: name,
      deckId: id,
    });

    navigate(`/info?id=${id}&name=${name}`, {
      state: {
        dataPoints: dataPoints,
        options: options,
      },
    });
  };

  return (
    <Card variant={'outlined'} onClick={onGraphClick}>
      <ToastContainer />
      <CardContent>
        <Box>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Typography variant={'h3'} textAlign={'center'}>
              {name}
            </Typography>
          </Box>

          {gettingDataPoints || !dataPoints || !options ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <CircularProgress style={{ color: JMU_DARK_PURPLE }} />
            </Box>
          ) : (
            <Card>
              <CardContent>
                <Line
                  height={isMobile ? '500px' : '35px'}
                  width={'100%'}
                  options={options}
                  data={dataPoints}
                />
              </CardContent>
            </Card>
          )}

          <Box
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            rowGap={'25px'}
            marginBottom={'25px'}
          >
            <Typography variant={'h6'}>There are currently</Typography>
            <Typography variant={'h3'}>{capacity}</Typography>
            <Typography variant={'h6'}>Spots open in {name}</Typography>
          </Box>

          {predictionLoading ? (
            <Box>
              <CircularProgress style={{ color: JMU_DARK_PURPLE }} />
            </Box>
          ) : (
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
              rowGap={'25px'}
            >
              <Typography variant={'h6'}>
                In one hour, there is expected to be
              </Typography>
              <Typography variant={'h3'}>
                {predictionData?.Prediction ?? 0}
                {predictionData?.Prediction &&
                predictionData?.Prediction &&
                predictionData?.Prediction > capacity ? (
                  <TrendingUpIcon color={'success'} />
                ) : (
                  <TrendingDownIcon color={'error'} />
                )}
              </Typography>
              <Typography variant={'h6'}>Spots open in {name}</Typography>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
