import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base query
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    return headers;
  },
});

