import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { JMU_DARK_PURPLE } from '../common/constants';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { toast, ToastContainer } from 'react-toastify';
import { plotPredictedCapacity } from '../common/graphingUtils';
import { Line } from 'react-chartjs-2';
import { isMobile } from 'react-device-detect';
import { useGetPredictionOnlyDataMutation } from '../api/api';
import { getErrorMessage } from '../common/getErrorMessage';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../config/firebaseConfig'; // Import RTK Query hooks

export default function PredictionOnlyGraph() {
  const [inputDate, setInputDate] = useState<Date>(new Date());
  const [inputDeckId, setInputDeckId] = useState<string>('0');

  const [getPredictionOnly, { isLoading: loading }] =
    useGetPredictionOnlyDataMutation();

  const [options, setOptions] = useState<any>(null);
  const [dataPoints, setDataPoints] = useState<any>(null);

  useEffect(() => {
    // Automatically load data on component mount
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    try {
      logEvent(analytics, 'only_prediction_graph', {
        page_title: 'OnlyPredictionGraph.tsx',
        date: inputDate.toDateString(),
        deckId: Number(inputDeckId),
      });

      const response = await getPredictionOnly({
        deckId: Number(inputDeckId),
        day: inputDate.getDate(),
        month: inputDate.getMonth() + 1,
        year: inputDate.getFullYear(),
      }).unwrap();
      const { PredictedData } = response;
      const { ops, data } = plotPredictedCapacity(inputDate, PredictedData);

      setOptions(ops);
      setDataPoints(data);
    } catch (e) {
      toast.error(`Failed to get prediction ${getErrorMessage(e)}`);
    }
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      rowGap={'25px'}
    >
      <Typography variant={'h4'}>Prediction Only Graphs</Typography>
      <ToastContainer />

      {loading && (
        <CircularProgress
          style={{ color: JMU_DARK_PURPLE, marginBottom: '10px' }}
        />
      )}

      {!loading && dataPoints !== null && options !== null && (
        <Box maxWidth={1200} width={window.innerWidth} height={isMobile ? '500px' : 'auto'}>
          <Line
            height={isMobile ? '500px' : '35px'}
            width={'100%'}
            options={options}
            data={dataPoints}
          />
        </Box>
      )}

      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        rowGap={'25px'}
      >
        <DatePicker
          value={dayjs(inputDate)}
          onChange={(e: Dayjs | null) => {
            if (e) setInputDate(e.toDate());
          }}
        />

        <FormControl variant={'outlined'}>
          <InputLabel id="select-form">Parking Decks</InputLabel>
          <Select
            onChange={(e) => setInputDeckId(e.target.value)}
            label="Parking Decks"
            value={inputDeckId}
          >
            <MenuItem value={'0'}>Champions Deck</MenuItem>
            <MenuItem value={'1'}>Chesapeake Deck</MenuItem>
            <MenuItem value={'2'}>East Campus Deck/Ballard Deck</MenuItem>
            <MenuItem value={'3'}>Grace Deck</MenuItem>
            <MenuItem value={'4'}>Warsaw Deck</MenuItem>
          </Select>
        </FormControl>

        <Button
          sx={{ mb: 8 }}
          fullWidth={isMobile}
          onClick={handleSubmit}
          disabled={loading}
          variant={'contained'}
          style={{ backgroundColor: JMU_DARK_PURPLE }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
