import { JMU_DARK_PURPLE, JMU_GOLD } from './constants';
import { isMobile } from 'react-device-detect';

// Define types for the data points
interface DataPoint {
  timeOfDay: number;
  capacity: number;
  year: number;
  month: number;
  day: number;
}

interface DataAndOptions {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      borderColor: string;
      backgroundColor: string;
    }[];
  };
  ops: object;
}

// Define the function for data and options
export function getDataAndOptions(
  targetDate: Date,
  dataPointsResp: DataPoint[],
  timeOptions: Intl.DateTimeFormatOptions,
  name: string
): DataAndOptions {
  const labels: string[] = [];
  const dataPoints: number[] = [];
  let maxCap = 0;

  for (const point of dataPointsResp) {
    const timeOfDay = point.timeOfDay;
    const capacity = point.capacity;
    targetDate.setHours(0, 0, timeOfDay);
    labels.push(targetDate.toLocaleTimeString(undefined, timeOptions));
    dataPoints.push(capacity);
    if (capacity > maxCap) {
      maxCap = capacity;
    }
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Capacity',
        data: dataPoints,
        borderColor: JMU_DARK_PURPLE,
        backgroundColor: JMU_DARK_PURPLE,
      },
    ],
  };

  const ops = {
    responsive: true,
    maintainAspectRatio: !isMobile,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Capacity',
        },
        min: 0,
        max: maxCap + 50,
      },
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Time(h)',
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: `Capacity vs Time, ${name} on ${targetDate.toLocaleDateString()}`,
      },
    },
  };

  return { data, ops };
}

export function getTimeOptions(): Intl.DateTimeFormatOptions {
  return { hour: 'numeric', minute: 'numeric', second: 'numeric' };
}

interface DataSet {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
}

interface RangeDataAndOptions {
  data: {
    labels: string[];
    datasets: DataSet[];
  };
  ops: object;
}

export function getRangeGraphDataAndOptions(
  startDate: Date,
  dataPointsResp: DataPoint[]
): RangeDataAndOptions {
  const labels: string[] = [];
  const dataSets: DataSet[] = [];
  let dataPoints: number[] = [];
  let currDate = new Date(startDate);
  let maxCap = 0;

  for (const point of dataPointsResp) {
    const capacity = point.capacity;
    const timeOfDay = point.timeOfDay;
    const pointDate = new Date(point.year, point.month - 1, point.day);
    pointDate.setHours(0, 0, timeOfDay);
    currDate.setHours(0, 0, timeOfDay);
    dataPoints.push(capacity);
    labels.push(pointDate.toLocaleTimeString());
    if (pointDate > currDate) {
      currDate = pointDate;
      dataSets.push({
        label: pointDate.toLocaleDateString(),
        data: [...dataPoints], // Clone array
        borderColor: JMU_DARK_PURPLE,
        backgroundColor: JMU_DARK_PURPLE,
      });
      dataPoints = [];
    }

    if (capacity > maxCap) {
      maxCap = capacity;
    }
  }

  const ops = {
    responsive: true,
    maintainAspectRatio: !isMobile,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Capacity',
        },
        min: 0,
        max: maxCap + 50,
      },
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Time(h)',
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: dataSets,
  };

  return { data, ops };
}

interface PredictionDataAndOptions {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      borderColor: string;
      backgroundColor: string;
    }[];
  };
  ops: object;
}

export function getActualAndPrediction(
  targetDate: Date,
  actualData: DataPoint[],
  predictionData: number[]
): PredictionDataAndOptions {
  const labels: string[] = [];
  const predictionX: number[] = [];
  const dataX: number[] = [];
  let maxCap = 0;

  const date = new Date(targetDate);
  const dataLength = actualData.length;
  for (let i = 0; i < dataLength; i++) {
    const predictionCapacity = predictionData[i];
    const actualPoint = actualData[i];
    const actualCapacity = actualPoint.capacity;
    const timeOfDay = actualPoint.timeOfDay;
    date.setHours(0, 0, timeOfDay);
    labels.push(date.toLocaleTimeString());
    predictionX.push(predictionCapacity);
    dataX.push(actualCapacity);
    if (actualCapacity > maxCap) {
      maxCap = actualCapacity;
    }
  }

  const ops = {
    responsive: true,
    maintainAspectRatio: !isMobile,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Capacity',
        },
        min: 0, // Ensure y-axis starts at 0
      },
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Time(h)',
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Actual Capacity vs Time',
        data: dataX,
        borderColor: JMU_DARK_PURPLE,
        backgroundColor: JMU_DARK_PURPLE,
      },
      {
        label: 'Predicted Capacity vs Time',
        data: predictionX,
        borderColor: JMU_GOLD,
        backgroundColor: JMU_GOLD,
      },
    ],
  };

  return { data, ops };
}

export function plotPredictedCapacity(
  targetDate: Date,
  predictionData: number[]
): PredictionDataAndOptions {
  const labels: string[] = [];
  const predictionX: number[] = predictionData;
  const predictionsCount = predictionData.length;

  // 600 is the number of data points (seems to be implied)

  // Loop through each prediction
  for (let i = 0; i < predictionsCount; i++) {
    // Create a new Date object for each label instead of modifying the same one
    const newDate = new Date(targetDate);

    // Calculate the correct time (e.g., every minute or hour)
    const hours = Math.floor((i * 24) / predictionsCount); // evenly distribute over 24 hours
    const minutes = Math.floor((i * 1440) / predictionsCount) % 60; // minutes for finer granularity

    newDate.setHours(hours, minutes, 0);
    labels.push(newDate.toLocaleTimeString()); // Use locale time string to format the time
  }

  const ops = {
    responsive: true,
    maintainAspectRatio: !isMobile,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Capacity',
        },
        min: 0, // Ensure y-axis starts at 0
      },
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Time(h)',
        },
      },
    },
  };

  const data = {
    labels: labels, // Labels are the times evenly distributed over the day
    datasets: [
      {
        label: 'Predicted Capacity vs Time',
        data: predictionX, // Plot only the predicted capacity
        borderColor: JMU_GOLD,
        backgroundColor: JMU_GOLD,
      },
    ],
  };

  return { data, ops };
}
