import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import MoreParkingInfo from './components/MoreParkingInfo';
import MainPage from './components/MainPage';
import PredictionGraph from './components/PredictionGraph';
import { Box } from '@mui/material';
import logo from './logo.svg';
import { ToastContainer } from 'react-toastify';
import PredictionOnlyGraph from './components/OnlyPredictionGraph';
import { analytics } from './config/firebaseConfig'; // Import initialized analytics
import { logEvent } from 'firebase/analytics'; // Import logEvent

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'page_view', { page_title: 'Home Page', page_path: '/' });
    }
  }, []);

  return (
    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
      <ToastContainer />
      <Box onClick={() => navigate('/')} display="flex" justifyContent="center">
        <img src={logo} alt="logo" className="parking-img-logo" />
      </Box>{' '}
      <Routes location={location}>
        <Route path="/" element={<MainPage />} />
        <Route path="/info" element={<MoreParkingInfo />} />
        <Route path="/prediction" element={<PredictionGraph />} />
        <Route path={'/prediction/only'} element={<PredictionOnlyGraph />} />
      </Routes>
    </Box>
  );
};

export default App;
